import { Component } from 'react';
import axios from 'axios';
import Loader from '../loader';


class Description extends Component {

	state = {
		page: {
			loading: true
		},
		info: null
	}


	componentDidMount = () => {
		this.requestDesciption()
	}

	requestDesciption = () => {
		let token = localStorage.getItem('access-token');
		axios.get(`/api/job/${this.props.info.code}`, {
			headers: {
				Authorization: `Bearer ${token}`
			}
		}).then(response => {
			let { page, info } = this.state;
			page.loading = false;
			info = response.data.data;
			this.setState({ info, page });
		}).catch(error => {
			let { page, info } = this.state;
			page.loading = false;
			info = null;
			this.setState({ info, page });
		});
	}

	render() {
		let { page, info } = this.state

		return page.loading ? <div><Loader /></div>
			:
			(
				<p>
					{info !== null && (info.what_they_do.length > 100 ? info.what_they_do.substring(0, 100 - 3) + "..." : info.what_they_do)}
				</p>
			)

	}
}



export default Description;