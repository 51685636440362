import axios from 'axios';
import moment from 'moment';
import React, { Component, createRef } from 'react';
import Navigation from '../../components/navigation.components';
// import { withOktaAuth } from '@okta/okta-react';

import { Button, Modal } from 'react-bootstrap';
import { withRouter, useLocation } from 'react-router-dom';

import '@fortawesome/fontawesome-free/css/all.min.css';

import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

class Admin extends Component {

	state = {
		page: {
			load: false
		},
		formData: {
			search: ""
		},
		formErrors: []
	}

	inputChangeHandler = e => {
		let { formData, formErrors } = { ...this.state };

		if (formErrors.includes(e.target.name)) {
			let idx = formErrors.indexOf(e.target.name);
			formErrors.splice(idx, 1);
		}

		formData[e.target.name] = e.target.value;
		this.setState({ ...this.state, formData, formErrors });
	}

	submitRequest = e => {
		e.preventDefault();

		let { formData, formErrors } = { ...this.state };

		formErrors = [];

		Object.keys(formData).map(key => {
			if (formData[key] === "") {
				formErrors.push(key)
			}
		})

		this.setState({ ...this.state, formData, formErrors }, () => {
			this.props.history.push(`/search?q=${formData.search}`);
		});
	}

	render() {
		let { page, formData, formErrors } = this.state

		return (
			<>
				<Navigation />

				<div className='container'>
					<div className='row justify-content-md-center'>
						<div className='col-8'>
							<div className="input-group input-group-lg mb-3 mt-5">
								<input
									type="text"
									className={`form-control ${formErrors.includes('search') ? 'is-invalid' : ""}`}
									placeholder="What are you looking for?"
									aria-label="Recipient's username"
									aria-describedby="button-addon2"
									value={formData.search}
									onChange={e => this.inputChangeHandler(e)}
									name="search"
								/>
								<button
									className="btn btn-outline-secondary"
									type="button"
									id="button-addon2"
									onClick={e => this.submitRequest(e)}
								>
									<FontAwesomeIcon icon={faSearch} />
								</button>
							</div>
							<a href={"#"} className='link-success' style={{ textDecoration: 'none', }}>or click here see complete list of careers</a>
						</div>
					</div>
				</div>
			</>
		)
	}
}


export default withRouter(Admin);

