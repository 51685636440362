
import axios from 'axios';
import moment from 'moment';
import { Component } from 'react';
import { Table } from 'react-bootstrap';

class Logs extends Component {

	state = {
		page: {
			load: true,
		},
		api: {
			logs: []
		},
		selected: null
	}


	componentDidMount = () => {
		this.logsRequest();
	}

	logsRequest = () => {
		axios.get('/api/logs')
			.then(response => {
				let { page, api } = this.state;
				page.load = false;
				api.logs = response.data;
				this.setState({ ...this.state, page, api });
			}).catch(error => {
				alert('Something went wrong');
			})
	}

	render() {
		let { page, api } = this.state

		console.log(api.logs);
		return page.load ?
			<div>Loading...</div>
			:
			<div className='container-fluid'>
				<div className='row'>
					<div className="col-12 mt-3">
						<Table striped bordered hover>
							<thead>
								<tr>
									<th>User</th>
									<th>Endpoint</th>
									<th>Created</th>
								</tr>
							</thead>
							<tbody>
								{api.logs.map(log => {
									return (
										<tr key={log._id}>
											<td>{log.user}</td>
											<td>{log.endpoint}</td>
											<td>{moment(log.created_at).format('LLL')}</td>
										</tr>
									)
								})}
							</tbody>
						</Table>
					</div>
				</div>
			</div>
	}
}

export default Logs;