import {
	Route,
	Switch,
	Redirect
} from 'react-router-dom';


const isAuthenticated = () => {
	const token = localStorage.getItem('access-token');
	try {
		if (!token) {
			return false;
		}
		return true;
	} catch (error) {
		return false;
	}
}

const PrivateRoute = ({ component: Component, ...rest }) => {
	return (
		<Route
			{...rest}
			render={props =>
				isAuthenticated() ? (
					<Component {...props} />
				) : (
					<Redirect
						to={{
							pathname: "/login",
						}}
					/>
				)
			}
		/>
	);
}

export default PrivateRoute;