// import React, { lazy } from 'react';
import { Route, useHistory, Redirect } from 'react-router-dom';

import Search from './pages/search';
import Login from './pages/login.page';
import Admin from './pages/admin';
import Career from './pages/career';
import Logs from './pages/logs';

import PrivateRoute from './routes/private.route';

const App = () => {
	return (

		<>
			<PrivateRoute path="/" exact component={Admin} />
			<PrivateRoute path="/search" exact component={Search} />
			<PrivateRoute path="/career/:id" exact component={Career} />
			<Route path='/login' render={() => <Login />} />
			<Route path='/public' render={() => <div>Unauthorized</div>} />
			<Route path="/logs" exact component={Logs} />

		</>
	);
};

export default App;