import { useState, useEffect } from 'react';
import { ListTask, CashCoin, LaptopFill, PersonWorkspace, Lightbulb, BodyText, Bookshelf, Bookmark, CardHeading, Controller } from 'react-bootstrap-icons';

const ListComponent = ({ title, list }) => {
	let [expand, setExpand] = useState(false);
	let [items, setItems] = useState([]);
	let limit = 3;

	useEffect(() => {

		if (list.length > limit) {
			let sliceItems = [...list].slice(0, limit);
			setItems(sliceItems);
		} else {
			setItems(list);
		}

		if (expand) {
			setItems(list);
		}

	}, [expand]);


	const ICON = {
		TASKS: <ListTask />,
		'AVERAGE SALARY': <CashCoin />,
		'TOOLS AND TECHNOLOGY': <LaptopFill />,
		SKILLS: <PersonWorkspace />,
		KNOWLEDGE: <Lightbulb />,
		'WORK ACTIVITIES': <BodyText />,
		"WORK STYLES": <Bookshelf />,
		"WORK VALUES": <Bookmark />,
		INTEREST: <Controller />,
		'WORK FAQS': <CardHeading />,
	}


	let expandHandler = e => {
		e.preventDefault();
		setItems(list);
		setExpand(true);
	}

	let seeLessHandler = e => {
		e.preventDefault();
		let sliceItems = [...list].slice(0, limit);
		setItems(sliceItems);

		setExpand(false);
	}

	return (
		<div className='col-12 mt-3'>
			<div className='card'>
				<div className='card-header text-center' style={{ textTransform: 'uppercase' }}>
					{title} {ICON[title.toUpperCase()]}
				</div>
				<ul className="list-group list-group-flush">
					{items.map((item, id) => {
						return <li className="list-group-item" key={id}>{item.statement}</li>
					})}

					{list.length > limit &&
						<li className="list-group-item text-center" key={`show_all_${title}`}>
							{!expand && <a href="#" onClick={e => expandHandler(e)}>+ Show All</a>}
							{expand && <a href="#" onClick={e => seeLessHandler(e)}>- See Less</a>}
						</li>
					}
				</ul>
			</div>
		</div>
	)
}

export default ListComponent;


