import React, { Component } from 'react';
import Navigation from '../../components/navigation.components';
import axios from 'axios';
import Loader from '../../components/loader';
import ListComponent from '../../components/list';
import { Clipboard, PatchCheck } from 'react-bootstrap-icons';
import CurrencyFormat from 'react-currency-format';


class Career extends Component {

	state = {
		page: {
			loaded: false,
			error: false,
			message: ""
		},
		id: null,
		api: {
			salary: null,
			fullReport: null
		}
	}

	componentDidMount() {
		let id = this.props.match.params.id;
		this.getCareerInfo(id);
		this.getSalaryInfo(id);
	}

	getSalaryInfo = id => {
		let token = localStorage.getItem('access-token');

		axios.get(`/api/job/${id}/salary`, {
			headers: {
				Authorization: `Bearer ${token}`
			}
		}).then(response => {
			let { api } = { ...this.state };
			api.salary = response.data.data;
			console.log(api.salary);
			this.setState({ ...this.state, api });
		}).catch(err => {
			console.log(err.response.data);
		})
	}

	getCareerInfo = (id) => {
		let token = localStorage.getItem('access-token');
		axios.get(`/api/job/${id}/full-report`, {
			headers: {
				Authorization: `Bearer ${token}`
			}
		}).then(response => {
			let { page, api, id } = { ...this.state };
			page.loaded = true;
			page.error = false;
			page.message = "";
			api.fullReport = response.data.data;
			console.log(api.fullReport);
			this.setState({ ...this.state, api, page, id });
		}).catch(err => {
			console.log(err.response.data);
		})
	}

	goToRelatedPositionHandler = (e, position) => {
		e.preventDefault();
		window.location.href = `/career/${position.code}`;
	}

	_displayTasks = () => {


		let api = this.state.api;

		let data = [];
		if (api.fullReport.hasOwnProperty('tasks')) {
			data = api.fullReport.tasks.task.map(task => {
				return {
					statement: <> {task.statement} {task.hasOwnProperty('score') && task.score.important && <span className="badge text-bg-warning ">Important</span>}</>
				}
			})
		}

		if (api.fullReport.hasOwnProperty('detailed_work_activities')) {
			api.fullReport.detailed_work_activities.activity.map((c, id) => {
				data.push({ statement: <>{c.name}</> })
			})
		}

		return <ListComponent title="Tasks" list={data} />
	}

	_displayKnowledge = () => {
		let data = [];
		if (this.state.api.fullReport.hasOwnProperty('skills')) {

			data = this.state.api.fullReport.knowledge.element.map(k => {
				return {
					statement: <> <strong>{k.name}</strong> - {k.description} {k.hasOwnProperty('score') && k.score.important && <span className="badge text-bg-warning ">Important</span>}</>
				}
			});

		}

		return <ListComponent title="Knowledge" list={data} />
	}

	_displayTechnology = () => {
		let api = this.state.api;

		let data = [];

		if (api.fullReport.hasOwnProperty('tools_technology')) {
			if (api.fullReport.tools_technology.hasOwnProperty('technology')) {
				api.fullReport.tools_technology.technology.category.map(item => {
					data.push({
						statement: <><strong>{item.title.name}</strong> <br /> <span className='text-muted'> {item.hasOwnProperty('example') && item.example.map(e => e.name + ", ")} </span></>
					})
				})
			}

			if (api.fullReport.tools_technology.hasOwnProperty('tools')) {
				api.fullReport.tools_technology.tools.category.map(item => {
					data.push({
						statement: <><strong>{item.title.name}</strong> <br /> <span className='text-muted'> {item.hasOwnProperty('example') && item.example.map(e => e.name + ", ")} </span></>
					})
				})
			}
		}

		return (
			<ListComponent title="Tools and Technology" list={data} />
		)
	}

	_displaySkills = () => {
		let api = this.state.api;

		if (api.fullReport.hasOwnProperty('skills')) {

			let data = api.fullReport.skills.element.map(skill => {
				return {
					statement: <> <strong>{skill.name}</strong> - {skill.description} {skill.hasOwnProperty('score') && skill.score.important && <span className="badge text-bg-warning ">Important</span>}</>
				}
			});


			return <ListComponent title="Skills" list={data} />
		}
	}

	_displayWorkActivities = () => {
		let api = this.state.api;


		if (api.fullReport.hasOwnProperty('work_activities')) {
			let data = api.fullReport.work_activities.element.map(work => {
				return {
					statement: <><strong>{work.name}</strong> - <span className='text-muted'> {work.description} </span></>
				}
			})

			return <ListComponent title="Work Activities" list={data} />
		}

	}

	_displayWorkStyles = () => {
		let api = this.state.api;

		let data = [];
		if (api.fullReport.hasOwnProperty('work_styles')) {
			data = api.fullReport.work_styles.element.map(work => {
				return {
					statement: <><strong>{work.name}</strong> - <span className='text-muted'> {work.description} </span> {work.hasOwnProperty('score') && work.score.important && <span className="badge text-bg-warning ">Important</span>}</>
				}
			})
		}
		return <ListComponent title="Work Styles" list={data} />
	}

	_displayWorkValues = () => {
		let api = this.state.api;

		let data = [];
		if (api.fullReport.hasOwnProperty('work_values')) {
			data = api.fullReport.work_values.element.map(work => {
				return {
					statement: <><strong>{work.name}</strong> - <span className='text-muted'> {work.description} </span> {work.hasOwnProperty('score') && work.score.important && <span className="badge text-bg-warning ">Important</span>}</>
				}
			})
		}
		return <ListComponent title="Work Values" list={data} />
	}


	_displayInterest = () => {
		let api = this.state.api;

		let data = [];
		if (api.fullReport.hasOwnProperty('interests')) {
			data = api.fullReport.interests.element.map(interest => {
				return {
					statement: <><strong>{interest.name}</strong> - <span className='text-muted'> {interest.description} </span> {interest.hasOwnProperty('score') && interest.score.important && <span className="badge text-bg-warning ">Important</span>}</>
				}
			})
		}
		return <ListComponent title="Interest" list={data} />
	}

	_displayWorkContext = () => {
		let api = this.state.api;

		let data = [];
		if (api.fullReport.hasOwnProperty('work_context')) {
			data = api.fullReport.work_context.element.map(work => {
				return {
					statement: <>
						<strong>{work.name}</strong> {work.hasOwnProperty('score') && work.score.important && <span className="badge text-bg-warning ">Important</span>} <br />
						<span className='text-muted'> {work.description} </span> <br /> <br />
						Responses are:
						<ul>
							{work.response.map((r, idx) => <li key={idx}>{r.percentage}% answers {r.name}</li>)}
						</ul>
					</>
				}
			})
		}
		return <ListComponent title="Work FAQs" list={data} />
	}

	_displaySalaryRange = () => {
		let salary = this.state.api.salary;
		if (salary !== null) {

			let data = [];

			if (salary.hasOwnProperty('salary')) {
				data.push({
					statement: <p style={{ margin: 0, padding: 0 }}>Hourly <CurrencyFormat value={salary.salary.hourly_median} displayType={'text'} thousandSeparator={true} prefix={'$'} /> </p>
				})

				data.push({
					statement: <p style={{ margin: 0, padding: 0 }}>Annualy <CurrencyFormat value={salary.salary.annual_median} displayType={'text'} thousandSeparator={true} prefix={'$'} /> </p>
				})
			}

			return data.length > 0 && <ListComponent title="Average Salary" list={data} />
		}

	}


	render() {
		let { page, api } = this.state;

		return (
			<>
				<Navigation />

				<div className='container'>
					{page.loaded ?
						<>
							<div className='row mt-3'>
								<div className='col-12'>
									<h3>{api.fullReport.occupation.title} {api.fullReport.occupation.tags.bright_outlook && <span className="badge text-bg-danger ">Hot</span>}</h3>
									<p>
										{api.fullReport.occupation.description}
									</p>
									<p className='text-muted'>Also called as {api.fullReport.occupation.sample_of_reported_job_titles?.title.join(", ")}</p>
								</div>
							</div>

							<div className='row mb-5'>
								<div className='col-8'>

									<div className='row'>
										{api.fullReport.hasOwnProperty('tasks') &&
											this._displayTasks()
										}

										{api.fullReport.hasOwnProperty('skills') &&
											this._displaySkills()
										}

										{api.fullReport.hasOwnProperty('knowledge') &&
											this._displayKnowledge()
										}

										{api.fullReport.hasOwnProperty('work_activities') &&
											this._displayWorkActivities()
										}

										{api.fullReport.hasOwnProperty('technology_skills') &&
											this._displayTechnology()
										}

										{api.fullReport.hasOwnProperty('work_styles') &&
											this._displayWorkStyles()
										}

										{api.fullReport.hasOwnProperty('work_values') &&
											this._displayWorkValues()
										}

										{api.fullReport.hasOwnProperty('interests') &&
											this._displayInterest()
										}

										{api.fullReport.hasOwnProperty('work_context') &&
											this._displayWorkContext()
										}

									</div>

								</div>

								{/* Related */}
								<div className='col-4 mt-3'>

									{api.fullReport.hasOwnProperty('education') &&
										<div className='row mb-3'>
											<div className='col-12'>
												<div className='card'>
													<div className='card-header text-center'>EDUCATION <PatchCheck /> </div>
													<ul className="list-group list-group-flush">
														{api.fullReport.education.level_required.category.map((c, id) => {
															return <li className="list-group-item " key={id}>{c.name}</li>
														})}
													</ul>
												</div>
											</div>
										</div>
									}

									{api.salary !== null && this._displaySalaryRange()}

									<div className='row mt-3'>
										<div className='col-12'>
											<div className='card'>
												<div className='card-header text-center'>RELATED POSITIONS <Clipboard /></div>
												<ul className="list-group list-group-flush">
													{api.fullReport.related_occupations.occupation.map((c, id) => {
														return <li onClick={e => this.goToRelatedPositionHandler(e, c)} className="list-group-item position-item" key={id}>{c.title} {c.tags.bright_outlook && <span className="badge text-bg-danger ">Hot</span>}</li>
													})}
												</ul>
											</div>
										</div>
									</div>

								</div>

							</div>
						</>
						:
						<div className='row justify-content-md-center'>
							<Loader />
						</div>
					}
				</div>
			</>
		)
	}
}


export default Career;