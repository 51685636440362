import React, { Component } from 'react';
import { withRouter, useLocation } from 'react-router-dom';
import Navigation from '../../components/navigation.components';

import Loader from '../../components/loader';
import qs from 'qs';
import axios from 'axios';
import Description from '../../components/description';

import './style.scss';

class Search extends Component {

	state = {
		page: {
			load: true,
			error: false,
			message: ''
		},
		search: '',
		result: null,
		openapi: null
	}

	componentDidMount = () => {

		let search = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });

		let searchCount = Object.keys(search).length;
		if (searchCount < 1 || !search.hasOwnProperty('q') || search.q === "") {
			window.location.href = '/';
		}

		let searchInput = this.state.search;
		searchInput = search.q;

		this.setState({ ...this.state, search: searchInput }, () => this.searchInput(search));
	}


	searchInput = (search) => {
		let { page, result } = this.state;
		let token = localStorage.getItem('access-token');
		axios.get('/api/job/search', {
			params: search,
			headers: {
				Authorization: `Bearer ${token}`
			}
		}).then(response => {
			let { page, result, openapi } = this.state;
			page.load = false;
			result = response.data.data.search;
			openapi = response.data.data.openApiRequest;
			this.setState({ ...this.state, result, page, openapi });
		}).catch(err => {
			let { page } = { ...this.state };
			page.load = false;
			page.error = true;
			page.message = err.response.data.error;
			this.setState({ page });
		})

		this.setState({ page, result });
	}

	goToCareerInfoHandler = (e, career) => {
		e.preventDefault();
		this.props.history.push(`/career/${career.code}`)
	}

	NewlineText = (string) => {
		const text = string;
		const newText = text.split('\n').map(str => <>{str}<br /></>);
		return newText;
	}


	render() {
		let { page, result, openapi, search } = this.state;

		return (
			<>
				<Navigation />

				<div className="container">

					{openapi !== null ?
						<div className='row mt-5'>
							<div className='col-12'>
								<h6>Open API result regarding {search}</h6> <br />
							</div>
							{openapi.whatIs &&
								<>
									<div>
										<b>What is a {search} ?</b> <br />
										{openapi.whatIs.choices.length > 0 && this.NewlineText(openapi.whatIs.choices[0].text)}
										<br /> <br />
									</div>
								</>
							}
							{openapi.skills &&
								<>
									<div>
										<b>What are the skills of a {search} ?</b> <br />
										{openapi.skills.choices.length > 0 && this.NewlineText(openapi.skills.choices[0].text)}
									</div>
								</>
							}
						</div>
						: null}


					<div className='row mt-5'>
						<div className='col-12'>
							<h6>Jobs related to "{search}"</h6>
						</div>
					</div>
					{
						page.load ?
							<div className='row justify-content-md-center'>
								<div className='col-1'>
									<Loader />
								</div>
							</div>
							:
							page.error ?
								<div className='row'>
									<div className='col-12'>
										<div className="alert alert-danger d-flex align-items-center" role="alert">
											<div>
												{page.message}
											</div>
										</div>
									</div>
								</div>
								:
								<div className='card'>
									<div className='card-header'>
										Found {result.total} items
									</div>

									<ul className="list-group list-group-flush">

										{result.career.map((career, idx) => {
											return (
												<li className="list-group-item position-item" key={idx} onClick={e => this.goToCareerInfoHandler(e, career)}>
													<div className='row'>
														<div className='col-12 mt-2 '>
															<h6>{career.title} {career.tags.bright_outlook && <span className="badge text-bg-danger ">Hot</span>}</h6>
														</div>
														{/* <div className='col-12 text-muted text-sm'>
															<Description info={career} />
														</div> */}
													</div>
												</li>
											)
										})}

									</ul>
								</div>

					}


				</div>
			</>
		)
	}
}

export default withRouter(Search);
